import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useSubsection,
  withSubsectionContextProvider,
} from "../../context/SubsectionContext";
import Ad from "../../components/Ad/Ad";
import RightBlocks from "../../components/RightBlocks/RightBlocks";
import LeftBlocks from "../../components/Section/LeftBlocks/LeftBlocks";
import { screenWidthIsHigherThan } from "../../hooks/useMediaQuery";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { buildAdTargets } from "../../utils/googleTagManagerHelper";
import Layouts from "../../components/TopLocalNews/Layouts/Layouts";
import withConfig from "../../utils/withConfig";
import { useStyles } from "./Subsection.style";
const LEADERBOARD_AD_SIZE = [728, 90];
const LEADERBOARD_MOBILE_AD_SIZE = [320, 100];

const Subsection = ({ town, section, subsection }) => {
  const classes = useStyles();
  const { localNews, loading, setSection, setSubsection, setTown, videoAd } =
    useSubsection();
  const [secondaryStories, setSecondaryStories] = useState([]);

  useEffect(() => {
    if (section) {
      setSection(section);
    }
    if (subsection) {
      setSubsection(subsection);
    }
    if (town) {
      setTown(town);
    }
  }, [town, section, subsection]);
  useEffect(() => {
    if (secondaryStories?.length === 0) {
      setSecondaryStories(localNews?.secondaryStories);
    }
  }, [localNews]);
  return (
    <GoogleReCaptchaProvider
      useRecaptprochaNet
      reCaptchaKey={withConfig("RECAPTCHA_KEY")}
      scriptProps={{ async: true, defer: true, appendTo: "body" }}
    >
      <div className={classes.main_container}>
        {screenWidthIsHigherThan(767) ? (
          <div className={classes.top_leaderboard}>
            <Ad
              ad_unit={"tap_leaderboard_001"}
              size={LEADERBOARD_AD_SIZE}
              targets={buildAdTargets(town, section, subsection)}
            />
          </div>
        ) : (
          <div className={classes.top_leaderboard_mobile}>
            <Ad
              ad_unit={"leaderboard_mobile"}
              size={LEADERBOARD_MOBILE_AD_SIZE}
              targets={buildAdTargets(town, section, subsection)}
            />
          </div>
        )}

        <Layouts
          adTargets={buildAdTargets(town, section, subsection)}
          videoAd={videoAd}
          localNews={localNews}
          section={section}
          subsection={subsection}
          defaultTemplate
          townForSection={town}
          loading={loading}
        />
        {screenWidthIsHigherThan(767) ? (
          <div className={classes.secondary_leaderboard}>
            <Ad
              ad_unit={"tap_leaderboard_002"}
              size={LEADERBOARD_AD_SIZE}
              targets={buildAdTargets(town, section, subsection)}
            />
          </div>
        ) : (
          <div className={classes.secondary_leaderboard_mobile}>
            <Ad
              ad_unit={"leaderboard_mobile_1"}
              size={LEADERBOARD_MOBILE_AD_SIZE}
              targets={buildAdTargets(town, section, subsection)}
            />
          </div>
        )}

        <subsection className={classes.display_blocks_section}>
          <div className={classes.display_blocks_container}>
            <div className={classes.left_block}>
              <LeftBlocks
                adTargets={buildAdTargets(town, section, subsection)}
                subsection={subsection}
                section={section}
              />
            </div>
            <div className={classes.right_block}>
              <RightBlocks
                adTargets={buildAdTargets(town, section, subsection)}
                town={town ? town : null}
                section={section}
                subsection={subsection}
              />
            </div>
          </div>
        </subsection>
      </div>
    </GoogleReCaptchaProvider>
  );
};

Subsection.propTypes = {
  subsection: PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
};
export default withSubsectionContextProvider(Subsection);
