import React, { Fragment } from "react";
import { arrayOf, oneOfType, shape, string } from "prop-types";
import { useStyles } from "./RightBlocks.style";
import { useTown } from "../../context/TownContext";
import {
  groupBlocksByClassWithSameOrder,
  showBlockSection,
} from "../../utils/helpers/homepageHelper";
import { useSection } from "../../context/SectionContext";
import { useSubsection } from "../../context/SubsectionContext";
import { screenWidthIsLowerThan } from "../../hooks/useMediaQuery";
import Box from "../../common/Box/Box";
import Divider from "../../common/Divider/Divider";
import clsx from "clsx";
import values from "../../common/Theme/themes/breakpoints/values";
import SubBlocks from "./SubBlocks";

const RightBlocks = ({ adTargets, town, section, subsection, noAds }) => {
  const useSectionContext = section ? true : false;
  const useSubsectionContext = useSectionContext && subsection ? true : false;
  const selectContext = () => {
    if (useSubsectionContext) {
      return useSubsection();
    } else if (useSectionContext) {
      return useSection();
    }
    return useTown();
  };
  const { rightBlocks, sponsors } = selectContext();
  const isMobile = screenWidthIsLowerThan(values.sm);
  const classes = useStyles();

  const filteredRightBlocks = noAds
    ? rightBlocks.filter((block) => !showBlockSection("ad", block))
    : rightBlocks;

  const groupedBlocks = groupBlocksByClassWithSameOrder(filteredRightBlocks);

  return groupedBlocks.map((innerBlocks, index) => {
    const isInnerBlockAd = innerBlocks.some((block) =>
      showBlockSection("ad", block)
    );

    return !isInnerBlockAd ? (
      <Box
        className={clsx(
          classes.no_ad_blocks,
          classes.no_printing,
          index !== 0 && classes.ad_block_with_margin_top
        )}
      >
        {innerBlocks.map((block, index) => {
          return (
            <Fragment key={`${index}-${block?.id}`}>
              <SubBlocks
                classes={classes}
                block={block}
                isMobile={isMobile}
                town={town}
                adTargets={adTargets}
                sponsors={sponsors}
                useSectionContext={selectContext()}
              />
              {innerBlocks.length > 0 &&
                innerBlocks.length - 1 !== index &&
                !isMobile && (
                  <Divider
                    variant="middle"
                    component="div"
                    className={classes.block_divider}
                  />
                )}
            </Fragment>
          );
        })}
      </Box>
    ) : (
      innerBlocks.map((block, index) => {
        return (
          <SubBlocks
            key={`${index}-${block?.id}`}
            classes={classes}
            block={block}
            isMobile={isMobile}
            town={town}
            adTargets={adTargets}
            sponsors={sponsors}
            useSectionContext={selectContext()}
          />
        );
      })
    );
  });
};

RightBlocks.propTypes = {
  adTargets: oneOfType([
    shape({
      towns: arrayOf(string),
      sections: arrayOf(string),
      sponsor_display: arrayOf(string),
    }),
    arrayOf(
      shape({
        towns: arrayOf(string),
        sections: arrayOf(string),
        sponsor_display: arrayOf(string),
      })
    ),
  ]),
};

export default RightBlocks;
